<template>
  <el-container class="shopList">
    <div v-if="!showCamScreen" style="width: 100%">
      <el-header class="box add">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>企业传感器配置列表</el-breadcrumb-item>
          <el-breadcrumb-item>传感器列表</el-breadcrumb-item>
        </el-breadcrumb>
      </el-header>

      <el-main class="sensor">
        <div>
          <el-form :inline="true" :model="form3" class="demo-form-inline">
            <el-col :span="4" class="mr10">
              <el-form-item size="medium">
                <el-input
                  placeholder="传感器id"
                  v-model="form3.device_id"
                  class="input-with-select"
                  clearable
                  @clear="search"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="mr10">
              <el-form-item size="medium">
                <el-input
                  placeholder="传感器名称"
                  v-model="form3.device_name"
                  class="input-with-select"
                  clearable
                  @clear="search"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1" class="tl mr10">
              <el-form-item size="medium">
                <el-button
                  type="primary"
                  @click="search"
                  class="general_bgc general_border cfff"
                  >查询</el-button
                >
              </el-form-item>
            </el-col>
            <el-col :span="1" class="tl mr10">
              <el-form-item size="medium">
                <el-button
                  type="primary"
                  @click="gotoGis"
                  class="general_bgc general_border cfff"
                  >gis地图展示</el-button
                >
              </el-form-item>
            </el-col>
          </el-form>
          <el-table border :data="tableData" style="width: 100%">
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="device_id" label="传感器id" width="180">
            </el-table-column>
            <el-table-column prop="device_name" label="传感器名称" width="180">
            </el-table-column>
            <el-table-column prop="unit" label="单位" width="80">
            </el-table-column
            ><el-table-column prop="max_value" label="最大值" width="80">
            </el-table-column
            ><el-table-column prop="min_value" label="最小值" width="80">
            </el-table-column
            ><el-table-column prop="show" label="是否对外展示" width="80">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.is_show"
                  active-text="是"
                  inactive-text="否"
                  @change="updateShow(scope.row.id)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="show" label="是否对监管展示" width="80">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.show1"
                  active-text="是"
                  inactive-text="否"
                  @change="updateShow1(scope.row.id)"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="show" label="模式" width="180">
              <template slot-scope="scope">
                <div v-if="[1, 200].includes(scope.row.type)">
                  <div class="df" style="flex-wrap: wrap">
                    <el-tag
                      style="margin: 5px 10px 5px 0"
                      v-for="item in scope.row.mode"
                      :key="item.id"
                      closable
                      size="medium"
                      :disable-transitions="false"
                      @close="handleRemoveMode(scope.row, item.id)"
                    >
                      {{ item.mode_name }}
                    </el-tag>
                    <el-button
                      class="button-new-tag"
                      size="mini"
                      style="margin: 5px 10px 5px 0"
                      @click="
                        modelVisible = true;
                        getType(scope.row);
                        choosedData = scope.row;
                      "
                      >+ 新增模式</el-button
                    >
                  </div>
                </div>
                <div v-else style="color: #999">当前传感器暂无模式设置</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  v-if="user_tag === 1"
                  type="text"
                  class="disib blue"
                  size="mini"
                  @click="handleManage(scope.row)"
                  >权限管理</el-button
                >
                <el-button
                  v-if="user_tag === 1"
                  type="text"
                  class="disib blue"
                  size="mini"
                  @click="handleNotice(scope.row)"
                  >上下线通知</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="i-page pagination-box disib mt20">
            <el-pagination
              background
              :page-size="10"
              layout="total,prev, pager, next"
              :total="total"
              :current-page="currentPage"
              @current-change="currentChange"
            >
              <el-bmap-marker
                v-for="item in sensorList"
                :key="item.id"
                vid="component-marker"
                :position="item.position"
              ></el-bmap-marker>
            </el-pagination>
          </div>
        </div>
      </el-main>
      <!-- <el-main class="sensor" v-else>
        <div class="df" style="width: 100%">
          <div style="flex: 1">
            <div style="width: 100%; height: 800px">
              <ve-bmap
                ref="mapRef"
                height="100%"
                :settings="chartSettings"
                :series="chartSeries"
                :tooltip="chartTooltip"
                :events="chartEvents"
              ></ve-bmap>
            </div>
          </div>
          <div style="flex: 0 0 400px">

          </div>
        </div>
      </el-main> -->
    </div>
    <div v-if="modelVisible">
      <el-dialog title="新增模式" :visible.sync="modelVisible">
        <div style="padding-bottom: 20px">
          <el-table :data="modelList" style="width: 100%" border>
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="modeName" label="模式名称" width="180">
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  v-if="
                    !choosedData.mode
                      .map((v) => v.id)
                      .includes(scope.row.modeId)
                  "
                  type="text"
                  class="disib blue"
                  size="mini"
                  @click="modelSetting(scope.row)"
                  >新增</el-button
                >
                <div v-else style="color: #999">模式已添加</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>

    <el-dialog title="权限管理" :visible.sync="manageVisible" width="35%">
      <div>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group
          v-model="checkedUser"
          @change="handleCheckedCitiesChange"
          size="medium"
        >
          <el-checkbox
            style="width: 20%; margin: 20px"
            v-for="user in userList"
            :label="user.user_id"
            :key="user.user_id"
            :border="true"
            ><div>{{ user.user_name }}</div></el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div>
        <el-button type="primary" round @click="handleSaveUser">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog title="权限管理" :visible.sync="noticeVisible" width="35%">
      <div>
        <el-checkbox
          :indeterminate="isNoticeIndeterminate"
          v-model="checkNoticeAll"
          @change="handleCheckAllNoticeChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group
          v-model="checkedNoticeUser"
          @change="handleCheckedCitiesNoticeChange"
          size="medium"
        >
          <el-checkbox
            style="width: 20%; margin: 20px"
            v-for="user in userNoticeList"
            :label="user.user_id"
            :key="user.user_id"
            :border="true"
            ><div>{{ user.user_name }}</div></el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div>
        <el-button type="primary" round @click="handleSaveNoticeUser"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import api from "../../../util/extra-api";
export default {
  data() {
    return {
      isList: true,
      modelVisible: false,
      choosedData: null,
      modelTypeOptions: [],
      editForm: {
        editName: "",
        editValue: [-20, 40],
      },
      modelTotal: 0,
      mQuery: {
        keyword: "",
        page: 1,
        limit: 10,
        type: 0,
      },
      typeOptions: [
        {
          value: "温度",
          label: "温度",
        },
      ],
      typeList: ["温度"],
      createFormData: {
        name: "",
        info: {
          type: "温度",
          value: [-20, 60],
        },
      },
      createRule: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        editName: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
        ],
      },
      createPopover: false,
      camId: null,
      timeValue: "",
      imageSrc: "",
      picLoading: false,
      picsVisible: false,
      manageVisible: false,
      tableData: [],
      videoUri: "https://extlive.esunadv.com/?url=",
      videoTitle: "",
      form3: {
        id: "",
        company_id: "",
        device_id: "",
        site_id: "",
        device_name: "",
        detail: "",
        pager_offset: "0",
        pager_openset: "10",
        show1: 0, //监管
        show2: 0, //对外
      },
      modelList: [],
      videoUrl: "",
      handleName: "新增",
      title: "企业摄像头",
      currentPage: 1,
      mCurrentPage: 1,
      allCurrentPage: 1,
      total: 0,
      allTotal: 0,
      dialogAddgsVisible: false,
      companyId: [],
      videoShow: false,
      checkAll: false,
      checkedUser: [],
      isIndeterminate: true,
      // rules: {
      //   cam_id: [
      //     { required: true, message: "请输入摄像头id", trigger: "blur" },
      //   ],
      //   site_id: [{ required: true, message: "请输入站点id", trigger: "blur" }],
      //   cam_name: [
      //     { required: true, message: "请输入摄像头名称", trigger: "blur" },
      //   ],
      // },
      userList: [],
      selectedId: null,
      showCamScreen: false,
      camList: [],
      camListQuery: {
        company_id: "",
        pager_offset: 0,
        pager_openset: 10,
      },
      camTotal: 0,
      user_tag: 2,
      noticeVisible: false,
      checkedNoticeUser: [],
      userNoticeList: [],
      value1: null,
      id: null,
      checkNoticeAll: false,
      isNoticeIndeterminate: false,
      // chartSettings: {
      //   key: "Ns87bUghsWzPirriDs7uPGPYQOlGUKYQ",
      //   bmap: {
      //     center: [120, 30],
      //     zoom: 5,
      //     roam: true,
      //     enableMapClick: false,
      //     mapStyleV2: { styleJson: mapConfig },
      //   },
      //   chartSeries: [],
      //   chartTooltip: {
      //     show: true,
      //     formatter: function (params, ticket, callback) {
      //       if (params.seriesType === "effectScatter") {
      //         return `${params.marker}${params.value[2]}`;
      //       } else if (params.seriesType === "lines") {
      //         return `${params.data.fromName} > ${params.data.toName}`;
      //       }
      //     },
      //   },
      //   chartEvents: {
      //     click: (v) => {
      //       //console.log("v: ", v);
      //       _this.$nextTick((_) => {
      //         _this.companyInfo.company_name = v.value[2];
      //         _this.companyInfo.address = v.value[4];
      //         _this.companyInfo.company_type_id = v.value[5];
      //         _this.companyId = v.value[3];
      //         var echarts = this.$refs.mapRef.echarts;
      //         var map = echarts.getModel().getComponent("bmap").getBMap();
      //         var point = new window.BMap.Point(v.value[0], v.value[1]);
      //         var infoWindow = new window.BMap.InfoWindow(
      //           _this.$refs.customInfowindow
      //         );
      //         // if (this.clickTimes == 0) {
      //         //   map.centerAndZoom(point, 14);
      //         // }
      //         map.setCenter(point);
      //         map.openInfoWindow(infoWindow, point);
      //         this.clickTimes++;
      //       });
      //     },
      //     finished: (_) => {
      //       // _this.getGeo();
      //     },
      //   },
      // },
    };
  },
  mounted() {
    this.form3.company_id = localStorage.getItem("company_id"); //获取当前企业
    this.camListQuery.company_id = localStorage.getItem("company_id"); //获取当前企业
    this.getData();
    // this.getModelList();
    let user = JSON.parse(localStorage.getItem("user"));
    this.user_tag = user.user_tag;
  },
  methods: {
    gotoGis() {
      this.$router.push({ path: "/admin/sensorTrace" });
    },
    test(data) {
      console.log(data);
    },
    handleRemoveMode(row, id) {
      console.log(row, id);
      api
        .post(`/v1/pc/deviceMode/removeMode`, {
          companyId: this.camListQuery.company_id,
          deviceId: row.id,
          modeId: id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.modelVisible = false;
            this.camListQuery.pager_offset = 0;
            this.getData();
          }
        });
    },
    getType(row) {
      api
        .get(
          `/v1/pc/deviceMode/modeSelect?companyId=${this.camListQuery.company_id}`,
          { type: row.type }
        )
        .then((res) => {
          if (res.code == 200) {
            // this.modelTypeOptions = res.data.map((v) => {
            //   return {
            //     value: v.modeId,
            //     label: v.modeName,
            //   };
            // });
            this.modelList = res.data;
          }
        });
    },
    modelSetting(row, value) {
      api
        .post(`/v1/pc/deviceMode/modeSetting`, {
          companyId: this.camListQuery.company_id,
          deviceId: this.choosedData.id,
          modeId: row.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.modelVisible = false;
            this.camListQuery.pager_offset = 0;
            this.getData();
          }
        });
    },
    popoverShow(row) {
      this.editForm.editName = row.modeName;
      this.editForm.editValue = [
        row.minValue.slice(0, -1),
        row.maxValue.slice(0, -1),
      ];
      console.log(this.editForm.editValue);
    },
    closeEPopover(row) {
      row.row = false;
    },
    modelEdit(row) {
      api
        .post(`/v1/pc/deviceMode/update`, {
          companyId: this.camListQuery.company_id,
          id: row.id,
          modeType: this.typeList.indexOf(row.modeType),
          modeName: this.editForm.editName,
          minValue: this.editForm.editValue[0],
          maxValue: this.editForm.editValue[1],
        })
        .then((res) => {
          if (res.code == 200) {
            this.mQuery.page = 1;
            this.mCurrentPage = 1;
            this.getModelList();
          }
        });
    },
    delModel(row) {
      api
        .post(`/v1/pc/deviceMode/delete`, {
          id: row.id,
          companyId: this.camListQuery.company_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.mQuery.page = 1;
            this.mCurrentPage = 1;
            this.getModelList();
          }
        });
    },
    getModelList() {
      api
        .get(
          `/v1/pc/deviceMode/list?companyId=${this.camListQuery.company_id}`,
          this.mQuery
        )
        .then((res) => {
          if (res.code == 200) {
            this.modelTotal = res.total;
            this.modelList = res.data.map((v) => {
              if (v.modeType === 0) {
                v.minValue += "℃";
                v.maxValue += "℃";
              }
              v.editPopover = false;
              v.modeType = this.typeList[v.modeType];
              return v;
            });
          }
        });
    },
    closePopover() {
      this.$refs["createForm"].resetFields();
      this.createPopover = false;
      this.createFormData.name = "";
      this.createFormData.type = "温度";
      this.createFormData.vaule = [-40, 100];
    },
    sliderChange(e, index) {
      if (index) {
        this.editForm.editValue = e;
      } else {
        this.createFormData.info.value = e;
      }
      // console.log(e, this.createFormData.info[index]);
    },
    createModel() {
      this.$refs["createForm"].validate((valid) => {
        if (valid) {
          let data = {
            modeName: this.createFormData.name,
            companyId: this.camListQuery.company_id,
          };
          data.modeType = this.typeList.indexOf(this.createFormData.info.type);
          data.maxValue = this.createFormData.info.value[1];
          data.minValue = this.createFormData.info.value[0];
          api.post(`/v1/pc/deviceMode/save`, data).then((res) => {
            if (res.code == 200) {
              this.$refs["createForm"].resetFields();
              this.createPopover = false;
              this.createFormData.name = "";
              this.createFormData.type = "温度";
              this.createFormData.vaule = [-40, 100];
              this.getModelList();
            }
          });
        } else {
          return false;
        }
      });
    },
    handleNotice(row) {
      this.selectedId = row.device_id;
      this.id = row.id;
      this.value1 = row.socket_number;
      axios
        .get("/pc/company-user-iot/getHasIotUser", {
          company_id: this.form3.company_id,
          other_table_name: "socket_online",
          other_table_id: row.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.userNoticeList = res.data;
            this.checkedNoticeUser = res.data
              .filter((m) => m.tag)
              .map((m) => m.user_id);
            this.noticeVisible = true;
          }
        });
    },
    handleSaveNoticeUser() {
      let data = {
        toUserIds: this.checkedNoticeUser,
        companyId: this.form3.company_id,
        type: "socket_online_message",
        relatedId: this.selectedId,
        value1: this.value1,
        id: this.id,
      };
      api.post("/v1/wx/warningNotice/pcAdd", data).then((res) => {
        if (res.code == 200) {
          this.noticeVisible = false;
        }
      });
    },
    handleCheckAllNoticeChange(val) {
      this.checkedNoticeUser = val
        ? this.userNoticeList.map((m) => m.user_id)
        : [];
      this.isNoticeIndeterminate = false;
    },
    handleCheckedCitiesNoticeChange(value) {
      this.checkedNoticeUser = value;
      //this.checkedNoticeUser = value.map(m => m.user_id)
      let checkedCount = value.length;
      this.checkNoticeAll = checkedCount === this.userNoticeList.length;
      this.isNoticeIndeterminate =
        checkedCount > 0 && checkedCount < this.userNoticeList.length;
    },
    updateShow(id) {
      axios.get("/pc/company-device/updateShow/" + id).then((res) => {
        if (res.code == 200) {
          this.getData();
        }
      });
    },
    updateShow1(id) {
      axios.get("/pc/company-device/updateShow1/" + id).then((res) => {
        if (res.code == 200) {
          this.getData();
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.camListQuery.pager_offset = val - 1;
      this.initCam();
    },

    handleSaveUser() {
      let data = {
        user_id: this.checkedUser,
        company_id: this.form3.company_id,
        other_table_name: "sensor",
        other_table_id: this.selectedId,
      };
      axios.post("/pc/company-user-iot/save", data).then((res) => {
        if (res.code == 200) {
          this.manageVisible = false;
        }
      });
    },
    handleCheckAllChange(val) {
      this.checkedUser = val ? this.userList.map((m) => m.user_id) : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      this.checkedUser = value;
      //this.checkedUser = value.map(m => m.user_id)
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.userList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.userList.length;
    },
    handleManage(row) {
      this.selectedId = row.id;
      axios
        .get("/pc/company-user-iot/getHasIotUser", {
          company_id: this.form3.company_id,
          other_table_name: "sensor",
          other_table_id: this.selectedId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.userList = res.data;
            this.checkedUser = res.data
              .filter((m) => m.tag)
              .map((m) => m.user_id);
            this.manageVisible = true;
          }
        });
    },

    getData() {
      this.form3.id = "";
      axios.get("/pc/company-device/list", this.form3).then((res) => {
        res = res.data;
        if (res) {
          this.tableData = res.company_device_list.map((m) => {
            return {
              ...m,
              is_show: m.is_show == 1,
            };
          });
          this.total = res.company_device_total;
        }
      });
    },
    search() {
      this.form3.pager_offset = "0";
      this.form3.pager_openset = "10";
      this.getData();
    },
    // mSearch() {
    //   this.mQuery.page = 1;
    //   this.mCurrentPage = 1;
    //   this.getModelList();
    // },

    // closeDialogAddgsVisible() {
    //   this.dialogAddgsVisible = false;
    // },
    currentChange(page) {
      this.currentPage = page;
      this.form3.pager_offset = String((page - 1) * 10);
      this.getData();
    },
    // mCurrentChange(page) {
    //   this.mQuery.page = page;
    //   this.getModelList();
    // },
    // allCurrentChange(page) {
    //   this.allCurrentPage = page;
    //   this.form3.pager_offset = String(page - 1);
    //   this.getAllData();
    // },
  },
};
</script>
<style lang="scss" scoped>
.sensor {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
  }
}
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    top: 0px;
    left: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }
  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}
$color: #2194e0;
.button_hover {
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  color: #d3d7d4;
  border: 2px solid $color;
  font-size: 14px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }
  &:hover {
    cursor: pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);
    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}

.activated {
  color: #1d953f;
}
.haccp-bg {
  transform-origin: 0px 0px 0px;
  background-image: url("../../../assets/image/mapbg.png");
  background-size: cover;
  background-position: 50% 0;
  background-color: rgb(0, 0, 0);
  min-width: auto;
  width: 100%;
  min-height: auto;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.card-item {
  flex: 1;
  width: 31%;
  min-width: 31%;
  max-width: 31%;
  height: 400px;
  background: transparent;
  margin-bottom: 30px;
  border: 0.5px solid aqua;
  border-radius: 6px;
}
.card-item-title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  background: transparent;
  text-align: center;
  font-size: 18px;
  color: #fff;
  border-bottom: 1px solid gainsboro;
}

.card-item-content {
  padding: 20px 10px;
  /*height: 20vw*/
}
.pagination-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/deep/ .el-table__empty-block {
  width: auto !important;
}
</style>
